<template>
  <div>
    <ValidationObserver slim ref="observer" v-slot="{ valid }">
      <EuiContainer style="height: calc(100vh - 180px);">
        <EuiGrid gutters>
          <EuiGridRow>
            <EuiGridColumn>
              <EuiHeading :level="1" bold>{{ $t('profile.title') }}</EuiHeading>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow>
            <EuiGridColumn width="1/3">
              <EuiBlock :title="$t('profile.blockBody.title.contactDetails')">
                <div v-if="user" class="eui-u-flex eui-u-mb-8 eui-u-flex-col">
                  <div class="eui-u-flex">
                    <div class="font-bold pr-2 eui-u-text-blue-grey">{{ $t('profile.label.lastname') }}</div>
                    <div>{{ user.lastname }}</div>
                  </div>
                  <div class="eui-u-flex">
                    <div class="font-bold pr-2 eui-u-text-blue-grey">{{ $t('profile.label.firstname') }}</div>
                    <div>{{ user.firstname }}</div>
                  </div>
                  <div class="eui-u-flex">
                    <div class="font-bold pr-2 eui-u-text-blue-grey">{{ $t('profile.label.email') }}</div>
                    <div>{{ user.email }}</div>
                  </div>
                </div>
                <EuiHeading :level="4" bold class="mb-3">{{ $t('profile.blockBody.title.loginCredentials') }}</EuiHeading>
                <div class="flex flex-col flex-1 pr-3" v-if="user">
                  <ValidationProvider :name="$t('profile.label.login')" rules="required" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.login')"
                                  type="text"
                                  v-model.trim="login"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('profile.label.actualPassword')" rules="required" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.actualPassword')"
                                  type="password"
                                  v-model.trim="oldPassword"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"
                                  autocomplete="off"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('profile.label.newPassword')" :rules="shouldUpdatePassword ? 'required|profilePassword' : ''" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.newPassword')"
                                  :type="passwordFieldType"
                                  v-model.trim="password"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"
                                  autocomplete="off"
                                  @keyup="passwordStrengthValidation"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('profile.label.confirmPassword')" :rules="shouldUpdatePassword ? 'required|profilePassword' : ''" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.confirmPassword')"
                                  :type="passwordFieldType"
                                  v-model.trim="confirmPassword"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  autocomplete="off"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <div class="eui-u-flex">
                    <div class="eui-u-flex eui-u-flex-1 eui-u-flex-col mt-2">
                      <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.is8long" :value="true" name="">{{ $t('settings.users.create.password.at.least.length') }}</EuiRadio>
                      <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasCaps" :value="true" name="">{{ $t('settings.users.create.password.at.least.caps') }}</EuiRadio>
                      <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasNumber" :value="true" name="">{{ $t('settings.users.create.password.at.least.number') }}</EuiRadio>
                      <EuiRadio class="eui-u-pointer-events-none" :model="passwordValidRequirement.hasSpecialChar" :value="true" name="">{{ $t('settings.users.create.password.at.least.special.char') }}</EuiRadio>
                    </div>
                    <div>
                      <EuiButton class="eui-u-p-2" color="primary" variant="text" @click.prevent="switchPasswordVisibility">
                        <template v-if="passwordFieldType === 'password'">
                          {{ $t('button.password.show') }}
                        </template>
                        <template v-else>
                          {{ $t('button.password.hide') }}
                        </template>
                      </EuiButton>
                    </div>
                  </div>
                </div>
              </EuiBlock>
            </EuiGridColumn>
          </EuiGridRow>
        </EuiGrid>
      </EuiContainer>
      <EuiBottomBar :class="bottomBarStyle">
        <EuiButton variant="text" color="primary" @click="cancel">{{ $t('button.cancel') }}</EuiButton>
        <EuiButton variant="raised" color="primary" :disabled="!valid || (changePassword && !validatePassword)" @click="save()">{{ $t('button.save') }}</EuiButton>
      </EuiBottomBar>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'ProfileIndex',
  computed: {
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
    }),
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
    }),
    validatePassword() {
      return this.password === this.confirmPassword;
    },
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    shouldUpdatePassword() {
      return (this.password !== '' || this.confirmPassword !== '');
    },
    shouldUpdateLogin() {
      return (this.login !== this.user.login);
    },
  },
  data() {
    return {
      changePassword: false,
      oldPassword: undefined,
      password: '',
      confirmPassword: '',
      isPasswordValid: true,
      passwordValidStrength: false,
      passwordFieldType: 'password',
      passwordValidRequirement: {
        is8long: false,
        hasCaps: false,
        hasNumber: false,
        hasSpecialChar: false,
      },
      login: '',
    };
  },
  methods: {
    ...mapActions({
      updateInternalUserCredentials: 'ModuleEdocSign/settingsUsers/updateInternalUserCredentials',
      update: 'ModuleEdocSign/settingsUsers/update',
    }),
    cancel() {
      if (this.$store.state.route.from.name) {
        this.$router.push({ name: this.$store.state.route.from.name });
      } else {
        this.$router.push({ path: '/' });
      }
    },
    save() {
      if (this.shouldUpdateLogin) {
        this.update({...this.user, login: this.login});
        this.cancel();
      }
      if (this.shouldUpdatePassword) {
        if (this.oldPassword && this.password && this.isPasswordValid && this.passwordValidStrength) {
          const params = { oldPassword: this.oldPassword, password: this.password, login: this.login };
          this.updateInternalUserCredentials(params);
          this.cancel();
        } else {
          if (this.password === undefined) {
            this.password = '';
          }
          if (this.oldPassword === undefined) {
            this.oldPassword = '';
          }
        }
      }
    },
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    /**
     * Password strength validation
     */
    passwordStrengthValidation() {
      if (this.password !== undefined) {
        this.passwordValidRequirement.is8long = new RegExp('^(?=.{8,})').test(this.password);
        this.passwordValidRequirement.hasNumber = new RegExp('^(?=.*[0-9])').test(this.password);
        this.passwordValidRequirement.hasCaps = new RegExp('^(?=.*[A-Z])').test(this.password);
        this.passwordValidRequirement.hasSpecialChar = new RegExp('^(?=.*[!@#$%^&*])').test(this.password);
        let errorCount = 0;
        Object.keys(this.passwordValidRequirement).forEach((key) => {
          if (!this.passwordValidRequirement[key]) {
            errorCount += 1;
          }
        });
        if (errorCount === 0) {
          this.passwordValidStrength = true;
        }
      }
    },
  },
  mounted() {
    this.login = this.user.login;
  },
};
</script>
